import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Circles } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { addIcon } from "../../../assests/Icons/icon";
import { countries } from "../../../common/globalVariables";
import {
  getCurrentUserData,
  notifyBugsnagError,
  setLocalUserData,
  setOrgLocalUserData,
  showToast
} from "../../../common/utils/Functions";
import DashboardLayout from "../../../components/layouts/DashboardLayout";
import ConfirmationModal from "../../../components/modals/ConfirmationModal/ConfirmationModal";
import PageHeading from "../../../components/pageHeading";
import ReactLoader from "../../../components/reactLoader/ReactLoader";
import InputField from "../../../components/theme/InputField";
import PhoneIntputField from "../../../components/theme/PhoneIntputField";
import ReactSelectField from "../../../components/theme/ReactSelectField";
import ThemeButton from "../../../components/theme/ThemeButton";
import useFetch from "../../../hooks/useFetch";
import { setUserData } from "../../../redux/common";
import {
  ProfileSchema,
  managerProfileSchema,
} from "../../../validations/ProfileSchema";
import styles from "../profile/profile.module.scss";

const Profile = () => {
  const navigate = useNavigate();
  const default_tipping_view_data = [
    { label: 'Simple', value: 0 },
    { label: 'Select Boxes', value: 1 }
  ];
  const [allState, setState] = useState<any>([]);
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const userData = getCurrentUserData(`${uuid}`);
  const data = getCurrentUserData(`${uuid}`);
  const [imagePreview, setImagePreview] = useState({
    url: "",
    name: "",
  });
  const [openDelete, setOpenDelete] = useState<boolean>(false); // state to handle Delete Property modal
  const [getMediaId, setGetMediaId] = useState();
  const [isNewImage, setIsNewImage] = useState(false);
  const [isRemovingImage, setIsRemovingImage] = useState(false);
  const [imageChange, setImageChange] = useState();

  /**
   * @function :-  to handle delete logo image Modal on/open
   */
  const handleDelete = () => setOpenDelete(false);
  /** Defined initialvalues for form */
  const initialValues =
    userData?.role_id === 2
      ? {
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        organization_name: "",
        address: "",
        city: "",
        state: "",
        zip_code: null,
        country_id: "",
        theme_color: "",
        font_color: "",
        media_id: null,
        tipping_view: null,
        facebook_url: null,
        yelp_url: null,
        google_site_url: null,
        trip_advisor_url: null
      }
      : {
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        phone_code_id: null,
        media_id: null,
        tipping_view: null
      };

  /** ------ @viewprofileApi ------- */
  const [
    viewProfileApi,
    { response: viewProfile, loading: profileLoading, error: profileError },
  ] = useFetch(`/organization/view/${data?.uuid}`);

  /** ------ @viewManagerProfileApi ------- */
  const [
    viewManagerProfileApi,
    {
      response: viewManagerProfile,
      loading: ManagerProfileLoading,
      error: ManagerProfileError,
    },
  ] = useFetch(`user/get-profile/${data?.uuid}`);

  /** ------ @updateProfileApi -------- */
  const [
    updateProfileApi,
    {
      response: updateProfile,
      loading: updateProfileLoading,
      error: updateProfileError,
    },
  ] = useFetch(
    userData?.role_id === 2
      ? `/organization/update/${data?.uuid}`
      : `/user/update-profile/${data?.uuid}`,
    { method: "put" }
  );

  const [
    stateApi,
    { response: stateresponse, loading: stateLoading, error: stateError },
  ] = useFetch(`/auth/get-states`, {
    method: "get",
  });

  /** Api definition to get media response */
  const [mediaApi, { response, loading, error }] = useFetch(
    "/common/upload-media",
    {
      method: "post",
    },
    "formdata",
    true
  );

  // /** Api definition to delete media response */
  const [
    mediaDelApi,
    { response: delResponse, loading: delLoading, error: delError },
  ] = useFetch(
    `/common/delete-media/${userData?.role_id === 2
      ? viewProfile?.data?.organization_media_details?.id
      : viewManagerProfile?.data?.media_details?.id
    }`,
    {
      method: "post",
    }
  );
  const handleAddIconClick = () => {
    // Trigger click event of file input when add icon is clicked
  };
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      try {
        const formdata = new FormData();
        formdata.append("files", file);
        formdata.append("type", "3");
        mediaApi(formdata);

        setImagePreview({
          name: "",
          url: "",
        });
        setIsNewImage(true);
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "mediaApi",
        });
      }
    }
  };

  const handleRemoveImage = () => {
    setImagePreview({
      name: "",
      url: "",
    });
    setIsRemovingImage(true);
    setOpenDelete(true); // Open confirmation modal
  };

  const handleConfirmRemoveImage = () => {
    if (isNewImage) {
      // Clear the newly uploaded image state
      setImagePreview({ url: "", name: "" });
      formik.setFieldValue("media_id", null);
      setIsNewImage(false); // Reset new image flag
    } else {
      // Call API to remove the existing image
      mediaDelApi({
        type: userData?.role_id === 2 ? "1" : "2",
      });
    }
    setOpenDelete(false); // Close confirmation modal
    setIsRemovingImage(false); // Reset removal state
  };

  useEffect(() => {
    if (response) {
      formik.setFieldValue("media_id", response.data[0]?.id);
      setImagePreview(response.data[0]);
    }
    if (error) {
      showToast(error.message as string, "error");
      formik.setFieldValue("media_id", null);
    }
  }, [response, error]);

  /** useEffect for delete the org logo */
  useEffect(() => {
    if (delResponse) {
      handleDelete();
      if (userData?.role_id === 2) {
        viewProfileApi();
      }
      if (userData?.role_id === 3) {
        viewManagerProfileApi();
      }
      setTimeout(() => {
        showToast(delResponse.message as string, "success");
      }, 500);
    }
    if (delError) {
      showToast(delError.message as string, "error");
    }
  }, [delResponse, delError]);

  const selected_value = default_tipping_view_data.map(item => item.value)
  /**@validation - Handle validations  */
  const formik = useFormik({
    initialValues,
    validationSchema:
      userData?.role_id === 2 ? ProfileSchema : managerProfileSchema,
    onSubmit: (values: any) => {
      const tippingView = values?.tipping_view?.value
      delete values.email;
      try {
        updateProfileApi({
          ...values,
          country_id: values.country_id?.value,
          tipping_view: tippingView
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "updateProfileApi",
        });
      }
    },
  });
  /**
   * @Call all organization data  using @viewProfileApi constant
   */
  useEffect(() => {
    if (userData?.role_id === 2) {
      const timer = setTimeout(() => {
        viewProfileApi();
      }, 500);
      return () => clearTimeout(timer);
    } else if (userData?.role_id === 3) {
      const timer = setTimeout(() => {
        viewManagerProfileApi();
      }, 500);
      return () => clearTimeout(timer);
    }
  }, []);

  /** useEffect for setting the values in fields if respons  */
  useEffect(() => {
    if (viewProfile) {
      formik.setFieldValue(
        "first_name",
        viewProfile?.data?.user_details?.first_name
      );
      formik.setFieldValue(
        "last_name",
        viewProfile?.data?.user_details?.last_name
      );
      formik.setFieldValue("email", viewProfile?.data?.user_details?.email);
      formik.setFieldValue(
        "phone_number",
        viewProfile?.data?.user_details?.phone_number != null ||
          viewProfile?.data?.user_details?.phone_number != "null"
          ? viewProfile?.data?.user_details?.phone_number
          : ""
      );
      formik.setFieldValue("organization_name", viewProfile?.data?.name);
      formik.setFieldValue("address", viewProfile?.data?.address);
      formik.setFieldValue("city", viewProfile?.data?.city);
      formik.setFieldValue("state", viewProfile?.data?.state);
      formik.setFieldValue("country_id", {
        value: viewProfile?.data?.country_details?.id,
        label: viewProfile?.data?.country_details?.name,
      });
      formik.setFieldValue("zip_code", viewProfile?.data?.zip_code);
      formik.setFieldValue("theme_color", viewProfile?.data?.theme_color);
      formik.setFieldValue("font_color", viewProfile?.data?.font_color);
      formik.setFieldValue("facebook_url", viewProfile?.data?.facebook_url);
      formik.setFieldValue("yelp_url", viewProfile?.data?.yelp_url);
      formik.setFieldValue("google_site_url", viewProfile?.data?.google_site_url);
      formik.setFieldValue("trip_advisor_url", viewProfile?.data?.trip_advisor_url);
      formik.setFieldValue(
        "media_id",
        viewProfile?.data?.organization_media_details?.id
      );
      let tippingViewLabel;

      if (viewProfile?.data?.tipping_view === 0) {
        tippingViewLabel = 'Simple';
      } else if (viewProfile?.data?.tipping_view === 1) {
        tippingViewLabel = 'Select Boxes';
      }

      formik.setFieldValue("tipping_view", {
        value: viewProfile?.data?.tipping_view,
        label: tippingViewLabel
      });
    }
    if (viewManagerProfile) {
      formik.setFieldValue("first_name", viewManagerProfile?.data?.first_name);
      formik.setFieldValue("email", viewManagerProfile?.data?.email);
      formik.setFieldValue(
        "phone_number",
        viewManagerProfile?.data?.phone_number
      );
      formik.setFieldValue(
        "media_id",
        viewManagerProfile?.data?.media_details?.id
      );
    }
    if (profileError) {
      showToast(profileError.message as string, "error");
    }
    if (ManagerProfileError) {
      showToast(ManagerProfileError?.message as string, "error");
    }
  }, [viewProfile, profileError, viewManagerProfile, ManagerProfileError]);

  useEffect(() => {
    if (updateProfile || delResponse) {
      // console.log("login as admin", updateProfile?.data?.updated_token);
      setLocalUserData(
        updateProfile?.data?.updated_token || delResponse?.data?.updated_token
      );
      showToast(updateProfile?.message as string, "success");
      if (uuid && userData?.role_id === 2) {
        setOrgLocalUserData(
          uuid,
          updateProfile?.data?.updated_token || delResponse?.data?.updated_token
        );
      }
      if (updateProfile) {
        window.location.href = uuid ? "/" + uuid + "/dashboard" : "/";
      }
      // dispatch(setUserData(getLocalUserData()));
      let data: any = {
        ...userData?.data,
        organization_details: { ...updateProfile?.data },
      };

      dispatch(setUserData(data));
    }
    if (updateProfileError) {
      showToast(updateProfileError.message as string, "error");
    }
  }, [updateProfile, updateProfileError, delResponse]);

  useEffect(() => {
    stateApi();
  }, []);

  useEffect(() => {
    const allNewstate: any = [];
    if (stateresponse) {
      stateresponse?.data?.map((item: any) => {
        allNewstate.push({ value: item?.code, label: item.name, id: item.id });
      });
      setState([
        { value: "", label: "Select State", id: null },
        ...allNewstate,
      ]);
    }
  }, [stateresponse]);

  useEffect(() => {
    if (viewProfile) {
      setImagePreview({
        url: viewProfile?.data?.organization_media_details?.url,
        name: viewProfile?.data?.organization_media_details?.name,
      });
    } else if (viewManagerProfile) {
      setImagePreview({
        url: viewManagerProfile?.data?.media_details?.url,
        name: "",
      });
    }
  }, [viewProfile, viewManagerProfile]);

  return (
    <DashboardLayout>
      {profileLoading || ManagerProfileLoading ? (
        <ReactLoader />
      ) : (
        <Container>
          <div className={styles.dash_page}>
            <PageHeading
              heading="Profile"
              subHeading="Here you can change your details."
            ></PageHeading>
          </div>

          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <Row className="g-xl-4 g-3">
              <Col xs={userData?.role_id === 2 ? 8 : 12}>
                <div className={styles.orgLogoWrap}>
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <label
                      style={{
                        position: "absolute",
                        right: 0,
                      }}
                      className={styles.addIcon}
                      onClick={handleAddIconClick}
                      htmlFor="profileUpload"
                    >
                      {addIcon}
                    </label>
                    <div
                      className={styles.orgLogo}
                      style={{
                        backgroundColor: viewProfile?.data?.theme_color,
                      }}
                    >
                      <span className={styles.innerContent}>
                        {loading || profileLoading ? (
                          <Circles
                            height="40"
                            width="40"
                            color="#2549E8"
                            ariaLabel="circles-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                          />
                        ) : imagePreview?.url ? (
                          <img src={imagePreview?.url} alt="" />
                        ) : (
                          <span
                            style={{
                              position: "absolute",
                              top: "50px",
                              left: "30px",
                              fontSize: "12px",
                            }}
                          >
                            No Image
                          </span>
                        )}
                      </span>

                      <input
                        id="profileUpload"
                        type="file"
                        accept="image/png, image/jpeg"
                        name="propertyLogo"
                        className={styles.hidden}
                        placeholder="Upload property Logo"
                        onChange={handleFileChange}
                      />
                    </div>
                  </div>
                  <h3>
                    {userData?.role_id === 3
                      ? viewManagerProfile?.data?.first_name
                      : "Organization Logo"}
                  </h3>
                </div>

                {imagePreview?.url ? (
                  <ThemeButton
                    className={styles.remove_btn}
                    onClick={handleRemoveImage}
                  >
                    Remove Image
                  </ThemeButton>
                ) : null}
              </Col>
              {userData?.role_id === 2 && (
                <Col
                  xl={4}
                  className="text-center d-flex justify-content-end mb-3 align-items-center"
                >
                  <ThemeButton loading={updateProfileLoading} type="submit">
                    Submit
                  </ThemeButton>
                </Col>
              )}
              <Col xxl={4} xl={4}>
                <div className={styles.addCateg}>
                  <InputField
                    formik={formik}
                    name="first_name"
                    label={
                      userData?.role_id === 2 ? "Admin First Name" : "Your Name"
                    }
                    placeholder={`Enter ${userData?.role_id === 2 ? "First" : "Your"
                      } Name`}
                  />
                </div>
              </Col>{" "}
              {userData?.role_id === 2 && (
                <Col xxl={4} xl={4}>
                  <div className={styles.addCateg}>
                    <InputField
                      formik={formik}
                      name="last_name"
                      label="Admin Last Name"
                      placeholder="Enter Last Name"
                    />
                  </div>
                </Col>
              )}
              <Col xxl={4} xl={4}>
                <div className={styles.addCateg}>
                  <InputField
                    name="email"
                    label="Admin Email"
                    formik={formik}
                    style={{ cursor: "not-allowed" }}
                    disabled={true}
                    placeholder="Enter Email"
                  />
                </div>
              </Col>
              <Col xxl={4} xl={4}>
                <PhoneIntputField
                  label="Admin Phone (Optional)"
                  name="phone_number"
                  formik={formik}
                  phoneCodeId={(phoneId: string) =>
                    formik.setFieldValue("phone_code_id", phoneId)
                  }
                />
              </Col>
              {userData?.role_id === 2 && (
                <>
                  <Col xxl={4} xl={4}>
                    <div className={styles.addCateg}>
                      <InputField
                        formik={formik}
                        name="organization_name"
                        label="Organization Name"
                        placeholder="Enter Organization Name"
                      />
                    </div>
                  </Col>
                  <Col xxl={4} xl={4}>
                    <div className={styles.addCateg}>
                      <ReactSelectField
                        name="tipping_view"
                        className="form_select"
                        options={default_tipping_view_data}
                        placeholder="Simple"
                        label="Default Tipping View"
                        formik={formik}
                      // menuIsOpen={true}
                      />
                    </div>
                  </Col>
                  <Col xxl={4} xl={4}>
                    <div className={styles.addCateg}>
                      <InputField
                        formik={formik}
                        name="address"
                        label="Organization Address (Optional)"
                        placeholder="Enter Organization Address"
                      />
                    </div>
                  </Col>
                  <Col xxl={4} xl={4}>
                    <div className={styles.addCateg}>
                      <InputField
                        formik={formik}
                        name="city"
                        label="City (Optional)"
                        placeholder="Enter City"
                      />
                    </div>
                  </Col>
                  <Col xxl={4} xl={4}>
                    <div className={styles.addCateg}>
                      <ReactSelectField
                        name="state"
                        className="form_select"
                        options={allState}
                        placeholder="Select State"
                        label="State (Optional)"
                        formik={formik}
                      />
                    </div>
                  </Col>
                  <Col xxl={4} xl={4}>
                    <div className={styles.addCateg}>
                      <InputField
                        formik={formik}
                        name="zip_code"
                        label="Zip Code (Optional)"
                        placeholder="Enter Zip Code"
                      />
                    </div>
                  </Col>
                  <Col xxl={4} xl={4}>
                    <ReactSelectField
                      name="country_id"
                      options={countries}
                      label="Country"
                      placeholder="Select Country"
                      className="form_select"
                      formik={formik}
                      formikErrorMesg={formik.errors?.country_id}
                    />
                  </Col>
                  <Col xxl={4} xl={4}>
                    <div className={styles.addCateg}>
                      <InputField
                        formik={formik}
                        name="theme_color"
                        label="Color Theme(HEX)"
                        placeholder="#000000"
                        type="color"
                      />
                    </div>
                  </Col>
                  <Col xxl={4} xl={4}>
                    <div className={styles.addCateg}>
                      <InputField
                        formik={formik}
                        name="font_color"
                        label="Font Color(HEX)"
                        placeholder="#000000"
                        type="color"
                      />
                    </div>
                  </Col>
                  <Col xxl={4} xl={4}>
                    <InputField
                      formik={formik}
                      name="trip_advisor_url"
                      label="Trip Advisor URL (Optional)"
                      placeholder="Enter URL"
                    />
                  </Col>{" "}
                  {/* <Row className="mt-1 lg-my-3 g-3"> */}
                  <Col xxl={4} xl={4}>
                    <InputField
                      formik={formik}
                      name="google_site_url"
                      label="Google Site URL (Optional)"
                      placeholder="Enter URL"
                      onKeyDown={(e) => {
                        if (e.key === " ") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Col>
                  <Col xxl={4} xl={4}>
                    <InputField
                      formik={formik}
                      name="yelp_url"
                      label="Yelp URL (Optional)"
                      placeholder="Enter URL"
                      onKeyDown={(e) => {
                        if (e.key === " ") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Col>
                  <Col xxl={4} xl={4}>
                    <InputField
                      formik={formik}
                      name="facebook_url"
                      label="Facebook URL (Optional)"
                      placeholder="Enter URL"
                      onKeyDown={(e) => {
                        if (e.key === " ") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Col>
                </>
              )}
              {userData?.role_id === 3 && (
                <Col
                  xl={12}
                  className="text-center d-flex justify-content-end mb-3 align-items-center"
                >
                  <ThemeButton loading={updateProfileLoading} type="submit">
                    Submit
                  </ThemeButton>
                </Col>
              )}
              <ConfirmationModal
                handleToggle={handleDelete}
                title="Are you sure you want to remove this logo?"
                show={openDelete}
                loading={delLoading}
                actionTitle="Remove"
                confirm={handleConfirmRemoveImage} // Use the updated function
              />
            </Row>
          </form>
        </Container>
      )}
    </DashboardLayout>
  );
};

export default Profile;
